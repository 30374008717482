<template>
  <div class="photo-item">
    <img :src="photoSrc" class="photo-blur" />
    <img :src="photoSrc" class="photo-original" />
    <div class="share-buttons">
      <!-- download photo -->
      <div
        class="download-btn button-download"
        @click="downloadPhoto"
        v-if="canDownload"
      >
        <v-progress-circular
          v-if="isLoading"
          indeterminate
          size="20"
          width="2"
        />
        <i class="material-icons" v-else>get_app</i>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    isLoading: false,
  }),
  props: {
    photo: {
      type: Object,
      default: () => ({}),
    },
    canDownload: Boolean,
  },
  computed: {
    downloadLink() {
      if (this.photo.original) return this.photo.original;
      if (this.photo.socialmedia) return this.photo.socialmedia;
      return this.photo.watermarked_preview;
    },
    photoId() {
      return this.photo.athlete_image_id;
    },
    photoSrc() {
      // socialmedia
      if (this.photo.socialmedia) return this.photo.socialmedia;
      // preview
      if (this.photo.preview) return this.photo.preview;

      return this.photo.watermarked_preview;
    },
  },
  methods: {
    async downloadPhoto() {
      if (this.isLoading) return;
      try {
        // this.isLoading = true;
        // let res = await fetch(this.downloadLink);
        // let blob = await res.blob();
        // this.isLoading = false;
        let a = document.createElement("a");
        // a.href = URL.createObjectURL(this.downloadLink);
        a.href = this.downloadLink;
        a.setAttribute("download", `${this.photo.athlete_image_id}.jpg`);
        a.click();
      } catch (error) {
        console.log("get photo error", error);
        this.isLoading = false;
      }
    },
    // preloadImage(photo) {
    //   if (!photo) return "";
    //   return `${photo.watermarked_preview}?x-test-img=membership`;
    // },
  },
};
</script>
<style lang="scss" scoped>
.photo-item {
  display: flex;
  position: relative;
  max-width: 308px;
  border-radius: 4px;
  height: 462px;
  overflow: hidden;
  .photo-blur {
    opacity: 0.666;
  }
  .share-buttons {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    // padding-top: 28px;
    // padding-right: 28px;
    padding-top: 20px;
    padding-right: 18px;
    .download-btn {
      height: 37px;
      width: 37px;
      border-radius: 19px;
      // margin-left: 12px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      // position: relative;
      @include cursorPointer;
    }
    .download-btn {
      color: #000000;
      background-color: rgba(255, 255, 255, 1);
      transition: all 200ms ease-out;
    }
    .download-btn:hover {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
}
.photo-blur {
  height: 100%;
  width: 100%;
  // flex-grow: 1;
  object-fit: cover;
  filter: blur(15px);
}
.photo-original {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translate(0, -50%);
  flex-grow: 1;
}
</style>